<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		
        <vue-jitsi-meet
            ref="jitsiRef"
            class="content"
            style="height: 100vh"
            domain="meet.silabu.com"
            :options="jitsiOptions"
            ></vue-jitsi-meet>
		
	</div>
</template>


<script>
	import { JitsiMeet } from "@mycure/vue-jitsi-meet";
	import socketIO from "socket.io-client"

	export default ({
		
		components: {
    		VueJitsiMeet: JitsiMeet,
		},

		data() {
			return {

				loadingData: false,
				activeTab: null,
				classDetails: {
                    // uuid: null,
                    name: null,
                    classUuid: null,
                    roomId: null,
                    isActive: false,
                    canStart: false,
                    createdAt: null
                },
				userDetails: {},

                socket: null,

                eventNotifier: {
                    startLiveClass: "new-live-class",
                    endLiveClass: "end-live-class",
                    liveClassStarted: "sessionStarted",
                    liveClassEnded: "sessionEnded",
                },

                className: "",
				search: "",
				message: "Hello",
				api: null,
				room: "test romm",
				username: "rabie",
      		}
		},
		created() {
            
            this.initializeDetails()
            this.startLiveVideo()
            
		},
        watch: {
            socket: {
				handler() {
                    this.socket.on(this.eventNotifier.liveClassEnded, (data) => {

                    });
					this.socket.on("emit-error", (data) => {
                        // console.log("==============================>0000000000000000000000000000000000")
                        // console.log(data)
                        // // setMessages([...messages, data])
                    });
				}
			},
            deep: true,
        },
		computed: {
            

            jitsiOptions() {

                return {
                    roomName: this.$route.params.uuid,
                    domain: "https://meet.silabu.com",
                    userInfo: {
                        displayName: this.userDetails != null ? this.userDetails.firstName + " " + this.userDetails.lastName : '',
                        avatarURL: this.userDetails != null ? this.userDetails.profilePicUrl : ''
                    },
                    lang: 'en',
                    configOverwrite: {
                        subject: this.className,
                        enableNoisyMicDetection: false,
                        startWithAudioMuted: false,
                    },
                    interfaceConfigOverwrite: {
                        SHOW_JITSI_WATERMARK: false,
                    },
                    onload: this.onIFrameLoad,
                };
            },
        },
		methods: {

            async initializeDetails() {
				const userDetails = JSON.parse(await localStorage.getItem('user_details'));
                
				this.userDetails = userDetails;
				let token = await localStorage.getItem("user_token")

                let className = await localStorage.getItem("class_name")

                this.classDetails.classUuid = this.$route.params.uuid;
                this.classDetails.name = className;
                this.classDetails.roomId = this.$route.params.uuid;
                this.classDetails.isActive = true;
                this.classDetails.canStart = true;
                this.classDetails.createdAt = Date.now();

				this.socket = socketIO.connect("https://dev.silabu.com" , {
					transports: ["websocket"],
					auth: { token: `${token}` }        
				});

			},

			async startLiveVideo() {

				let token = await localStorage.getItem("user_token")
                let user = await localStorage.getItem("user_details")

                this.className = await localStorage.getItem("class_name")
				let roomId = this.$route.params.uuid;
				let jwt = token;

				this.jitsiHandler(roomId, jwt);
			},

			jitsiHandler(roomId, jwt) {
				this.jitsiOptions.roomName = roomId;
            
				setTimeout(() => {
					this.onIFrameLoad();
				}, 3000);
			},

            onIFrameLoad () {
                this.$refs.jitsiRef.addEventListener('videoConferenceJoined', this.videoConferenceJoined);
                this.$refs.jitsiRef.addEventListener('readyToClose', this.readyToClose);
                
            },
            
            videoConferenceJoined(data) {
                this.socket.emit(this.eventNotifier.liveClassStarted, this.classDetails)

                this.$refs.jitsiRef.executeCommand('avatarUrl',  this.userDetails != null ? this.userDetails.profilePicUrl : '');
            },

            readyToClose() {

                if(this.classDetails.canStart) {
                    this.socket.emit(this.eventNotifier.liveClassEnded, this.classDetails)
                }

                setTimeout(() => this.closeWindow(), 2000);
            
            },

            closeWindow() {
                try {
                    window.close()
                    history.pushState(null, null, location.href);
                    window.onpopstate = function () {
                        history.go(1);
                    };
                    this.$router.push(`/my-classes/view/${this.$route.params.uuid}`);
                } catch (error) {
                    history.pushState(null, null, location.href);
                    window.onpopstate = function () {
                        history.go(1);
                    };
                    this.$router.push(`/my-classes/view/${this.$route.params.uuid}`);
                }
            }

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.leftwatermark, .watermark {
    width: 710;
    height: 80px;
    background-image: url('./logo.png') !important;
}

/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>